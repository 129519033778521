<template>
    <div class="container pb-5">
        <div class="crumb-view crumbs-detail text-start">
            <div class="crumb-item">
                <router-link to="/main">홈</router-link><span class="crumb-space">&gt;</span>
            </div>
            <div class="crumb-item">
                <span>Gold Badge (#1379)</span>
            </div>
        </div>

        <div class="top-info">
            <div class="left-info overflow-hidden">
                <div class="bg_img" style="background-image: url(https://via.placeholder.com/512x512);">
                </div>
                
                <div class="nft_badges">
                    <div class="exch bg-primary">Exchanged</div>
                    <div class="badge badge btn-main position-absolute top-0 end-0 badge-primary"><span>200x</span></div>
                </div>
            </div>
            <div class="right-info">
                <div class="top-view section-item">
                    <div class="info-title pb-3">
                        <div class="info-name">
                        Gold Badge (#1379)
                        </div>
                        <div class="buttons">
                            <a href="javascript:void(0)" v-popover:f1>
                                <span class="cursor_pointer icon bg-primary">
                                    <i class="fal fa-share"></i>
                                </span>
                                <popover name="f1">
                                    <ul>
                                        <li v-for="(item,index) in ['링크 복사','트위터에 공유']" 
                                        :key="index">
                                            {{item}}
                                        </li>
                                    </ul>
                                </popover>  
                            </a>
                        </div>
                    </div>
                    <div class="operate-info">
                        <div class="info-datas">
                        <div class="data-item">
                            <div class="title">카운트다운</div>
                            <div class="value text-main">
                                <div class="delay-values"><span class="delay-item">10</span><span class="delay-sep">:</span><span class="delay-item">03</span><span class="delay-sep">:</span><span class="delay-item">34</span><span class="delay-sep">.</span><span class="delay-item">34</span></div>
                            </div>
                        </div>
                        <div class="data-item">
                            <div class="title">경매 시작 가격 (COIN)<span class="text-main">≈ $199</span></div>
                            <div title="450000000.000000" class="value"><span>450,000,000</span></div>
                        </div>
                        </div>
                        <div class="buttons pt-4">
                            <a href="javascript:void(0)" @click="$router.push({name: 'Login'})" class="btn btn-main d-block py-3" v-if="!login">로그인</a>
                        </div>
                        <!---->
                    </div>
                </div>
                <div class="data-view section-item">
                    <div class="auction-datas">
                        <div class="tab-content">
                        <div class="data-list">
                            <div class="data-item">
                                <div>소유자</div>
                                <div><a target="_blank" href="https://bscscan.com/address/0x3F05a0d05CEe1952cD237fc4241aF2dd48132D64">0x3F05...2D64</a></div>
                            </div>
                            <div class="data-item">
                                <div>계약 주소</div>
                                <div><a target="_blank" href="https://bscscan.com/token/0x7c160b4bd3460909e5526f117b8c821a8e2ccd4f">0x7c16...cd4f</a></div>
                            </div>
                            <div class="data-item">
                                <div>Token ID</div>
                                <div>
                                    #1379
                                </div>
                            </div>
                            <div class="data-item">
                                <div>자산 프로토콜</div>
                                <div>
                                    ERC721
                                </div>
                            </div>
                            <div class="data-item">
                                <div>자산 퍼스릭 체인</div>
                                <div>
                                    BSC
                                </div>
                            </div>
                            <div class="data-item">
                                <div class="text-start">Whether to exchange badges</div>
                                <div>
                                    Yes
                                </div>
                            </div>
                        </div>
                        </div>
                        <!---->
                    </div>
                </div>
            </div>
        </div>

        <div class="nft-history section-item-view">
            <div class="title">
                <div class="text">경매 내역</div>
                <div class="buttons small">나의 총 커미션：<b>0 COIN</b></div>
            </div>
            <div class="table_box">
                <table class="w-100">
                    <thead>
                        <th>지갑 주소</th>
                        <th>Hash</th>
                        <th>경매 가격 (COIN)</th>
                        <th>경매 시간</th>
                        <th>커미션</th>
                        <th>상태</th>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in historyList" :key="index">
                            <td>{{item.address}}</td>
                            <td>{{item.hash}}</td>
                            <td>{{item.price}}</td>
                            <td>{{item.time}}</td>
                            <td>{{item.commission}}</td>
                            <td>{{item.status}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="section-item-view">
            <div class="title">경매 규칙</div>
            <div class="contents">
                <div>1. 카운트다운이 1시간 미만이면 매 입찰마다 10분씩 증가하며, 최대 1시간까지 증가할 수 있습니다.</div>
                <div>2. 경매 가격은 매번 10%씩 인상됩니다. 카운트다운이 끝나면 경매 제품은 마지막 입찰자가 획득하게 됩니다.</div>
                <div>3. 그 중 할증액의 20%는 이전 입찰자가 획득하며, 80%는 경매인이 획득합니다.</div>
                <div>4. 이 플랫폼은 입찰 수수료에서 20%를 서비스 수수료로 청구하며, 이 중 40%는 NFT 마이닝풀로 가고, 50%는 $COIN로 바꿔 소각되고, 10%는 개발자에게 보내집니다.</div>
                <div>5. 경매 성공 후, 플랫폼은 경매인 수입의 3%를 서비스 수수료로 부과합니다. 이 중 40%는 NFT 마이닝 풀로, 50%는 COIN로 Swap하여 파기하고 10%는 개발자 수익으로 지급됩니다.</div>
                <div>6. 경매 종료 후 경매인은 경매 완료 제품 페이지로 이동하여 NFT경매  수익을 얻거나 유찰 되었을 경우, NFT를 수령할 수 있습니다. 비고: 입찰자가 NFT를 수령한 후 경매인은 자동으로 수익을 수령합니다.</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            login: this.$store.state.login,
            historyList: [
                {
                    address: "1dofejidalfjmeopfjdaszofkjddjfoej",
                    hash: "1dofejidalfjmeopfjdaszofkjddjfoej",
                    price: 2.222,
                    time: "13:22:11",
                    commission: 444,
                    status: "완료",
                },
                {
                    address: "1dofejidalfjmeopfjdaszofkjddjfoej",
                    hash: "1dofejidalfjmeopfjdaszofkjddjfoej",
                    price: 2.222,
                    time: "13:22:11",
                    commission: 444,
                    status: "완료",
                },
                {
                    address: "1dofejidalfjmeopfjdaszofkjddjfoej",
                    hash: "1dofejidalfjmeopfjdaszofkjddjfoej",
                    price: 2.222,
                    time: "13:22:11",
                    commission: 444,
                    status: "완료",
                },
                {
                    address: "1dofejidalfjmeopfjdaszofkjddjfoej",
                    hash: "1dofejidalfjmeopfjdaszofkjddjfoej",
                    price: 2.222,
                    time: "13:22:11",
                    commission: 444,
                    status: "완료",
                },
                {
                    address: "1dofejidalfjmeopfjdaszofkjddjfoej",
                    hash: "1dofejidalfjmeopfjdaszofkjddjfoej",
                    price: 2.222,
                    time: "13:22:11",
                    commission: 444,
                    status: "완료",
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .top-info{
        display: flex;
        padding: 32px;
        background: linear-gradient(89deg, #f3dbf3, #ede0ff);
        border-radius: 32px;

        .left-info{
            position: relative;
            flex: 1;
            background-color: #ffffff;
            box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.15);
            border-radius: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .right-info{
            margin-left: 32px;
            display: flex;
            flex-direction: column;
            flex: 1;

            .operate-info{
                text-align: left;
                flex: 1;

                .info-datas{
                    display: flex;

                    .data-item{
                        flex: 1;

                        .title{
                            color: #505f79;
                            font-size: 16px;
                            display: flex;
                            align-items: center;
                        }

                        .value{
                            font-weight: 600;
                            font-size: 30px;
                            margin-top: 6px;
                        }
                    }
                }
            }
        }

        .top-view{
            padding-bottom: 32px;

            .info-title{
                display: flex;
                justify-content: space-between;
                text-align: left;

                .info-name{
                    color: #2f2e41;
                    font-size: 24px;
                    font-weight: 600;
                    -webkit-box-flex: 1;
                    -webkit-flex: 1;
                    -ms-flex: 1;
                    flex: 1;
                    line-height: 1.2;
                }

                .buttons{
                    display: flex;
                    align-items: center;
                    margin-left: 12px;

                    .icon{
                        width: 32px;
                        height: 32px;
                        padding: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        border-radius: 100%;
                        font-size: 14px;
                    }
                }
            }
        }

        .data-view{
            flex: 1;
            margin-top: 32px;

            .data-item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 12px;

                a{
                    text-decoration: underline;
                }
            }
        }

        .section-item{
            background: #ffffff;
            box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.15);
            border-radius: 24px;
            padding: 32px;
        }
    }
    
    .section-item-view{
        background-color: #fff;
        box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
        border-radius: 32px;
        padding: 28px 32px;
        margin-top: 40px;

        .title{
            font-weight: 700;
            color: #333;
            font-size: 20px;
            padding-bottom: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #f5f8fb;
        }

        .contents{
            font-size: 14px;
            color: #505f79;
            margin-top: 16px;
            text-align: left;
        }
    }

    .table_box{
        overflow-x: auto;
        border-top: 1px solid #e9e9e9;
        font-size: 14px;
        
        thead{
            border-bottom: 1px solid #e9e9e9;
            th{
                padding: 10px 0;
                font-weight: normal;
            }
        }
        tr td{
            padding: 10px 0;
        }
    }
@media screen and (max-width:768px) {
    .top-info{
        flex-wrap: wrap;
        padding: 15px;
        .left-info{
            width:100%;
            flex: 100%;
            margin-bottom: 15px;
        }
        .right-info{
            width:100%;
            margin-left:0;
            .operate-info{

                .info-datas{
                    flex-wrap: wrap;
                    .data-item{
                        width: 100%;
                    }
                }
            }
        }
    }
    .table_box{
        th:nth-of-type(1),td:nth-of-type(1),
        th:nth-of-type(3),td:nth-of-type(3),
        th:nth-of-type(6),td:nth-of-type(6){
            display: none;
        }
        th:nth-of-type(2),td:nth-of-type(2){
            max-width: 100px;
            overflow-x: auto;
        }

    }
}
</style>