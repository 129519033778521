<template>
    <div class="container pb-5">
        <section class="section">
            <ul class="fw-bold rounded-pill select-type d-flex mb-5">
                <li class="py-3 rounded-pill" 
                :class="{'bg-primary text-white':active==index}" 
                @click="active = index"
                v-for="(item,index) in ['경매', '시장']" :key="index">
                    {{item}}
                </li>
            </ul>
            <div class="info-box d-flex w-100 bg-primary p-4 rounded-lg rounded-pill">
                <div class="d-flex flex-column">
                    <h3 class="text-white">$ {{numberWithCommas(sales)}}</h3>
                    <small class="text-white">총 매출액</small>
                </div>
                
                <div class="d-flex flex-column">
                    <h3 class="text-white">{{numberWithCommas(transaction)}}</h3>
                    <small class="text-white">총 트랜잭션 시간</small>
                </div>
                
                <div class="d-flex flex-column">
                    <h3 class="text-white">{{numberWithCommas(auction)}}</h3>
                    <small class="text-white">총 경매 시간</small>
                </div>

                <div class="d-flex flex-column">
                    <h3 class="text-white">$ {{numberWithCommas(commission)}}</h3>
                    <small class="text-white">총 경매 커미션</small>
                </div>
            </div>
        </section>
        <div class="option-box">
            <div class="d-flex justify-content-between">
                <ul class="text-start">
                    <li class="btn rounded-pill fw-bold me-2" 
                    :class="{'btn-main text-white': index==now_tab, 'btn-outline-main': index!=now_tab}"
                    @click="now_tab = index"
                    v-for="(item,index) in tagList" :key="index">
                        {{item.title}}
                    </li>
                </ul>
                <ul class="d-flex justify-content-start filter_list">
                    <li v-popover:f1 class="me-3">
                        <span class="cursor_pointer">{{filter1List[filter1]}} <i class="fas fa-sort-down"></i></span>
                        <popover name="f1">
                            <ul>
                                <li v-for="(item,index) in filter1List" 
                                @click="filter1=index"
                                :class="{'text-main':filter1==index}"
                                :key="index">
                                    {{item}}
                                </li>
                            </ul>
                        </popover>    
                    </li>
                    <li v-popover:f2  class="me-3">
                        <span class="cursor_pointer">{{filter2List[filter2]}} <i class="fas fa-sort-down"></i></span>
                        <popover name="f2">
                            <ul>
                                <li v-for="(item,index) in filter2List"
                                @click="filter2=index"
                                :class="{'text-main':filter2==index}"
                                :key="index">
                                    {{item}}
                                </li>
                            </ul>
                        </popover>    
                    </li>
                    <li v-popover:f3>
                        <span class="cursor_pointer">{{filter3List[filter3]}} <i class="fas fa-sort-down"></i></span>
                        <popover name="f3">
                            <ul>
                                <li v-for="(item,index) in filter3List" 
                                @click="filter3=index"
                                :class="{'text-main':filter3==index}"
                                :key="index">
                                    {{item}}
                                </li>
                            </ul>
                        </popover>    
                    </li>
                </ul>

            </div>
        </div>
        <div class="d-flex justify-content-between my-4 search_box">
            <div class="search-input">
                <input type="text" placeholder="키워드 검색 입력" class="ellipsis">
                <a class="btn btn-main button-40 d-flex align-items-center">
                    <i class="fas fa-search"></i>
                </a>
            </div>
            <div class="auction_list_btn">
                <button class="btn btn-main d-flex align-items-center justify-content-center" @click="$store.commit('walletLoginPpFn',true)">경매 내역</button>
            </div>
        </div>
        <section>
            <ul class="d-flex gap-3 sales_list list-4-gap list-2-mobile flex-wrap">
                <li v-for="(item,index) in salesList" :key="index" class="shadow d-flex flex-column bg-white position-relative overflow-hidden list-4">
                    <router-link class="p-3" :to="`/nft/${index}`">
                        <div class="img_box d-flex rounded overflow-hidden flex-column">
                            <img :src="item.img" alt="" class="rounded">
                            <b class="py-2">{{item.name}}</b>
                        </div>
                        <div class="d-flex flex-column justify-content-around mt-2">
                            <div class="d-flex justify-content-between align-items-center mb-1">
                                <span class="text-pale">경매 시작 가격</span>
                                <span class="fw-bold">{{item.start_price}}</span>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="text-pale">카운트 다운</span>
                                <span class="fw-bold">{{item.count}}</span>
                            </div>
                        </div>
                        <div class="nft_badges">
                            <div class="exch bg-primary">Exchanged</div>
                            <div class="badge badge btn-main position-absolute top-0 end-0 badge-primary"><span>200x</span></div>
                        </div>
                    </router-link>
                </li>
            </ul>
        </section>
    </div>
</template>

<script>
export default {
    data(){
        return{
            sales:1692024879,
            transaction:7832,
            auction:416883,
            commission: 11111,

            active: 0,

            now_tab: 0,
            tagList: [
                {
                    title: "All NFT"
                },
                {
                    title: "Family"
                },
                {
                    title: "Crypto DNAxCAT"
                },
                {
                    title: "StarMon Badge"
                },
            ],

            filter1: 0,
            filter2: 0,
            filter3: 0,
            
            filter1List: ['모든 상태','공개 경매','커뮤니티 경매'],
            filter2List: ['경매중','종료됨'],
            filter3List: ["최신 가격","최신 발표","종료 시간","최저가","최고가"],
            
            salesList:[
                {
                    img: require('@/assets/img/banner2.jpg'),
                    name:'Comming Soon (#239)',
                    start_price:147,
                    count: '11:00:00'
                },
                {
                    img: require('@/assets/img/banner2.jpg'),
                    name:'Comming Soon (#240)',
                    start_price:147,
                    count: '11:00:00'
                },
                {
                    img: require('@/assets/img/banner2.jpg'),
                    name:'Comming Soon (#241)',
                    start_price:147,
                    count: '11:00:00'
                },
                {
                    img: require('@/assets/img/banner2.jpg'),
                    name:'Comming Soon (#241)',
                    start_price:147,
                    count: '11:00:00'
                },
                {
                    img: require('@/assets/img/banner2.jpg'),
                    name:'Comming Soon (#241)',
                    start_price:147,
                    count: '11:00:00'
                }
            ],
        }
    },
    methods:{
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    }
}
</script>

<style lang="scss" scoped>
section{
    margin-bottom: 20px;
}
.status-badge{
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0; 
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    width: 100px;
    height: 30px;
}

.sales_list{
    li{
        width: calc(25% - 0.75em);
        border-radius: 16px;
    }
    // .img_box{
    //     background-color: #f4f4f4;
    // }
}

.tab_btn{
    display: flex;
    padding-bottom: 20px;

    li{
        min-width: 120px;
        margin-right: 10px;
    }
}
.filter_list{
    li{
        padding: 10px 15px;
    }
}




.search-input{
    display: flex;
    position: relative;

    input{
        border: 1px solid #541792;
        border-radius: 30px;
        height: 40px;
        line-height: 40px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        font-size: 14px;
        padding: 0 60px 0 24px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        outline: none;
        width: 240px;
        color: #541792;
    }

    a{    
        position: absolute;
        right: 0px;
        top: 0px;
        bottom: 5px;
        height: auto;
        padding: 4px 16px;
        transform: translateY(0)!important;
    }
}

.btn-outline-main{
    background-color: transparent;
    &:hover{
        color: inherit;
    }
}

.option-box{
    .btn{
        margin-bottom: 8px;
    }
}
@media screen and (max-width:768px) {
    .option-box{
        >div{
            font-size: .8em; 
            flex-wrap: wrap;
            width: 100%;
            .btn{
                font-size: 14px;
            }
            >ul{
                width:100%;

            }
            .filter_list{
                justify-content: space-between !important;
            }
        }
    }
    .sales_list{
        li, li a{
            padding: 0 !important;
        }
        li a>div{
            padding: 5px 10px;
        }
        li a>div b{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
@media screen and (max-width:575px) {
    .search_box{
        flex-flow: column;
        .search-input{
            width: 100%;
            margin-bottom: 0.5em;

            input{
                width: 100%;
            }
            a{
                height: 40px;
            }
        }
        .auction_list_btn{
            width: 100%;

            button{
                width: 100%;
            }
        }
    }
}
</style>
